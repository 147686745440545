import * as React from "react"
import { Container } from "react-bootstrap"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = () => (
  <Layout>
    <Seo title="Services" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>
        Services
        </h1>
      </Container>
    </div>
    <Container className="singleCol two-rem-padding">
      
      <h2>For Parents</h2>
      <h3>Consultation Services</h3>
      <ul>
        <li>Initial conversation with parents about their dyslexic learner’s educational background and current needs</li>
        <li>Follow- up conversations as needed by telephone or videoconference</li>
      </ul>

      <h3>Documentation Review</h3>
      <ul>
        <li>Review of student’s psycho-educational or neuropsychological evaluation report</li>
        <li>Review of other reports (Speech & Language, OT, etc.)</li>
        <li>Review of existing IEPs/504 Plans, including present levels, areas of need, goals and services</li>
        <li>Analysis of student’s current program, including feedback on compliance</li>
        <li>School observations</li>
      </ul>
      <h3>Advocacy Services</h3>
      <ul>
        <li>Support and guidance for parents in preparation for an upcoming meeting (IEP) including drafting of goals, etc.</li>
        <li>Introductory conversations with school staff and administrators when appropriate</li>
        <li>Attendance at IEP as appropriate</li>
      </ul>

      <h3>Resources</h3>
      <ul>
        <li>Referrals to private providers and community agencies</li>
        <li>Referrals to Special Education attorneys as requested </li>
      </ul>

      <h2 className="mt-3">For Students</h2>
      <h3>Dyslexia Screening </h3>
      <p>
        The Slingerland® screening assessments identify areas of strength and relative	weaknesses related to language processing to inform instruction.
      </p>
      <h3>
        Remediation using the Slingerland® Approach to Structured Literacy
      </h3>
      <p>
        The focus of this evidence-based approach is on integrating the three language modalities – visual, kinesthetic, and auditory through instruction in handwriting, spelling, morphology, written expression, vocabulary, decoding, reading fluency, and reading comprehension. This is accomplished through explicit, structured, and sequential instruction to enable students to make meaningful progress in both spelling and reading.
      </p>
    </Container>
  </Layout>
)

export default IndexPage
